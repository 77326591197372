@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #f8fafc;
  height: 100vh;
  font-family: 'MANROPE';
}
#root {
  height: 100%;
}

.container {
  color: white;
  display: flex;
  height: 100vh;
  background-color: #313131;
}

*:focus {
  box-shadow: none !important;
  outline: none;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
